import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { LabmoduleService } from '../../services/labmodule.service';

@Component({
  selector: 'app-lab-infobar',
  templateUrl: './lab-infobar.component.html',
  styleUrls: ['./lab-infobar.component.css']
})
export class LabInfobarComponent implements OnInit {
  userId: any;
  labDetails: any = {}
  labName: string = ""
  labAddress: any = ""
  constructor(private LabService: LabmoduleService, private authService: AuthService,) { }

  ngOnInit(): void {
    this.userId = this.authService.getUser()
    this.getLabDetailsbyDocId()
  }

  getLabDetailsbyDocId() {
    // Retrieve LAB_DETAILS from local storage
    const labDetailsString = localStorage.getItem("LAB_DETAILS");

    // Check if the retrieved data is not null before parsing
    let labdetals = labDetailsString ? JSON.parse(labDetailsString) : null;
    this.labName = labdetals.name
    this.labAddress = labdetals.address

  }
}
