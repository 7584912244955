import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Constants } from "../../config/constants";

@Injectable({
  providedIn: 'root'
})
export class LabmoduleService {

  constructor(private http: HttpClient, private router: Router) { 
    if (Constants.APICONFIG.HOST == 'localhost') {
        this.APIURL = 'http://localhost:3000/v1';
        this.PIVOTALAPIURL = 'http://localhost:3200/api';
    }
  }

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };
  APIURL = `${Constants.APICONFIG.PROTOCOL}://${Constants.APICONFIG.HOST}:${Constants.APICONFIG.PORT}/v1`;
  PIVOTALAPIURL = `${Constants.APICONFIG.PROTOCOL}://${Constants.APICONFIG.HOST}:${Constants.APICONFIG.PORT}/api`;

  public getLabByDoctorId(req_vars:any): Observable<any> {
    return this.http.post(`${this.APIURL}/getLabByDoctorId`, req_vars);
  }
  public getLabReportsByReqId(req_vars:any): Observable<any> {
    return this.http.post(`${this.APIURL}/getLabReportsByReqId`, req_vars);
  }
 
  public getRequisitionsByLabId(req_vars:any): Observable<any> {
    return this.http.post(`${this.APIURL}/getRequisitionsByLabId`, req_vars)
  }
  public updateRequisitionByLab(req_vars:any): Observable<any> {
    return this.http.post(`${this.APIURL}/updateRequisitionByLab`, req_vars)
  }

  public LabUploadReport(req_vars:any): Observable<any> {
    return this.http.post(`${this.PIVOTALAPIURL}/v3/LabUploadReport`, req_vars)
  }
  public deleteLabReport(req_vars:any): Observable<any> {
    return this.http.post(`${this.APIURL}/deleteLabReport`, req_vars)
}
public getAllLabReportOfPatient(req_vars:any): Observable<any> {
  return this.http.post(`${this.APIURL}/getAllLabReportOfPatient`, req_vars);
}
}
